import React, {useState, useEffect}  from "react"
import ReactMarkdown from 'react-markdown';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from "gatsby"
import {useGetCmsImages} from '../hooks/use-get-cms-images';
import {useSiteSocials} from "../hooks/use-site-socials";
import NavItem from "./navItem";
import NavIcon from "./navIcon";
import {globalHistory} from '@reach/router';
import clsx from "clsx";

const navItems = [
  {
    label: 'About Us',
    to: '/about-us'
  },
  {
    label: 'Meet the Team',
    to: '/about-us#team'
  },
  {
    label: 'Recruitment Services',
    to: '/recruitment-services'
  },
  {
    label: 'Available Temp Jobs',
    to: '/temp-jobs'
  },
  {
    label: 'Community Page',
    to: '/community'
  },
  {
    label: 'Contact Us',
    to: '/contact-us'
  }
];

const Header = ({
  noBackground = false
}) => {
  const [mobileActive, setMobileActive] = useState(false);
  useEffect(() => {
    return globalHistory.listen(({action}) => {
      if(action === 'PUSH') setMobileActive(false)
    })
  }, [setMobileActive])
  const query = useStaticQuery(graphql`
    query {
      navbar: mdx(fields: {filename: {eq: "navbar"}}) {
        frontmatter {
          logo
          announcement
        }
      }
    }
  `);
  const {
    navbar: {
      frontmatter: {
        logo: logo_path,
        announcement
      }
    }
  } = query;
  const {
    facebookUrl,
    linkedInUrl,
    instagramUrl,
    tiktokUrl,
    twitterUrl
  } = useSiteSocials();
  const logo_image = useGetCmsImages((logo_path.split('/')[2]).split('.')[0])[0]?.node;
  return (
    <>
      <section className="bg-primary-light">
        <div className="xl:container mx-auto px-4 lg:px-8 py-2 text-black text-center font-bold [&_a]:text-primary-dark [&_a]:font-bold [&_p]:w-full text-sm md:text-base">
          <ReactMarkdown>{announcement}</ReactMarkdown>
        </div>
      </section>

      <header className={clsx("z-10 relative", !noBackground && 'h-0')}>
        <nav className={clsx("py-2 lg:py-0", noBackground ? 'bg-primary' : 'bg-transparent')}>
          <div className="xl:container flex flex-nowrap items-center mx-auto gap-x-3 lg:gap-x-6 px-4 lg:px-8 py-2 lg:py-4">
            <div className="flex flex-1 lg:flex-none items-center">
              <Link to="/">
                <GatsbyImage image={getImage(logo_image.image)}
                  alt="Temp and Agnew Logo"
                  className="max-w-[100px] lg:max-w-[120px]" />
              </Link>
            </div>
            <ul className="hidden lg:flex flex-1 flex-row lg:items-center p-4 lg:gap-x-6">
              {navItems.map((i,k) => (
                <NavItem key={k}
                  item={i} />
              ))}
            </ul>
            <ul className="hidden lg:flex items-center p-4 lg:gap-x-3">
              {twitterUrl &&
                <NavIcon url={twitterUrl}
                  icon="iconTwitter" />
              }
              {tiktokUrl &&
                <NavIcon url={tiktokUrl}
                  icon="iconTiktok" />
              }
              {facebookUrl &&
                <NavIcon url={facebookUrl}
                  icon="iconFacebook" />
              }
              {linkedInUrl &&
                <NavIcon url={linkedInUrl}
                icon="iconLinkedIn" />
              }
              {instagramUrl &&
                <NavIcon url={instagramUrl}
                  icon="iconInstagram" />
              }
            </ul>
            <button type="button" 
              className="p-4 items-center text-white lg:hidden">
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" 
                aria-hidden="true" 
                fill="currentColor" 
                viewBox="0 0 20 20" 
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setMobileActive(true)}>
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
              </svg>
            </button>
          </div>
        </nav>
      </header>

      <nav className={clsx('lg:hidden bg-primary border-gray-200 absolute h-screen w-[100vw] z-30 shadow-lg transition-[left] duration-500', {
        'left-[-150vw]': !mobileActive,
        'left-0': mobileActive
      })}>
        <div className="relative w-full flex flex-col px-4 py-12">
          <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512" 
            className="w-5 cursor-pointer fill-white hover:fill-primary-dark transition-all right-[30px] top-[20px] absolute lg:hidden"
            onClick={() => setMobileActive(false)}>
              <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
          </svg>
          <div className="flex justify-center lg:pl-4 py-2 lg:py-4 mb-4">
            <Link to="/">
              <GatsbyImage image={getImage(logo_image.image)}
                alt="Temp and Agnew Logo"
                className="max-w-[140px]" />
            </Link>
          </div>
          <ul className="flex flex-col gap-y-3 font-bold text-center mb-8">
            {navItems.map((i,k) => (
              <NavItem key={k}
                item={i} />
            ))}
          </ul>
          <ul className="flex flex-row items-center justify-center gap-x-3 font-bold text-center mb-4">
            {twitterUrl &&
              <NavIcon url={twitterUrl}
                icon="iconTwitter" />
            }
            {tiktokUrl &&
              <NavIcon url={tiktokUrl}
                icon="iconTiktok" />
            }
            {facebookUrl &&
              <NavIcon url={facebookUrl}
                icon="iconFacebook" />
            }
            {linkedInUrl &&
              <NavIcon url={linkedInUrl}
              icon="iconLinkedIn" />
            }
            {instagramUrl &&
              <NavIcon url={instagramUrl}
                icon="iconInstagram" />
            }
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Header
