import React from "react"

const NavIcon = ({ url, icon }) => {
  return (
    <li>
      <a
        href={url}
        className="group flex items-center justify-center p-1.5 rounded-full border-2 md:border border-white md:border-primary-extralight border-solid hover:border-primary-dark transition-all"
      >
        <svg className="w-3 h-3 fill-white md:fill-primary-extralight group-hover:fill-primary-dark transition-all">
          <use href={`#${icon}`}></use>
        </svg>
      </a>
    </li>
  )
}

export default NavIcon
