import React, {useState} from "react";
import { graphql, useStaticQuery } from 'gatsby';
import FooterIcon from "./footerIcon";
import {useSiteSocials} from "../hooks/use-site-socials";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from "gatsby"
import {useGetCmsImages} from '../hooks/use-get-cms-images';
import {Formik} from "formik";
import {encode} from '../utils/encode';
import clsx from "clsx";

const navListOne = [
  {
    label: 'Recruitment Services',
    to: '/recruitment-services'
  },
  {
    label: 'Available Temp Jobs',
    to: '/temp-jobs'
  },
  {
    label: 'Find a Temp',
    to: '/client'
  },
  {
    label: 'Register for Temp Work',
    to: '/candidates'
  }
];

const navListTwo = [
  {
    label: 'About Us',
    to: '/about-us'
  },
  {
    label: 'Community Page',
    to: '/community'
  },
  {
    label: 'Privacy',
    to: '/privacy-policy'
  },
  {
    label: 'Contact Us',
    to: '/contact-us'
  }
];

const validateForm = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }
  return errors;
}

const Footer = ({
  entity
}) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);

  const onSubmit = (values, {setSubmitting, resetForm}) => {
    setTimeout(() => {
      setSubmitting(false);
      fetch("/", {
        method: "POST",
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        body: encode({
          "form-name": "subscribe",
          ...values
        }),
      })
      .then(response => {
        setSubmitSuccess(true);
        resetForm({email: ''});
      })
      .catch(error => {
        console.error(error);
        setSubmitFail(true);
      })
    })
  }

  const query = useStaticQuery(graphql`
    query {
      navbar: mdx(fields: {filename: {eq: "footer"}}) {
        frontmatter {
          logo
        }
      }
    }
  `);
  const {
    navbar: {
      frontmatter: {
        logo: logo_path,
      }
    }
  } = query;
  const {
    facebookUrl,
    linkedInUrl,
    instagramUrl,
    twitterUrl,
    tiktokUrl
  } = useSiteSocials();
  const logo_image = useGetCmsImages((logo_path.split('/')[2]).split('.')[0])[0]?.node;
  return (
    <footer className="bg-primary-dark pb-12 pt-12 md:pt-24 lg:pt-32 relative">
      <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1937 126.71"
        className="hidden md:block absolute top-0 left-0 w-full">
        <defs><linearGradient id="linear-gradient" x1="1937" y1="-5727.96" x2="0" y2="-5727.96" gradientTransform="matrix(1, 0, 0, -1, 0, -5664.6)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#2685bf"/><stop offset="0.16" stopColor="#2e8bc3"/><stop offset="0.39" stopColor="#449bcd"/><stop offset="0.69" stopColor="#67b5dd"/><stop offset="1" stopColor="#94d7f2"/></linearGradient></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2"><path style={{fillRule:'evenodd',fill:'url(#linear-gradient)'}} d="M1937,79.4V0H0V39.1c838.1,270.6,1084.5-194,1937,40.3"/></g></g></g>
      </svg>
      <div className="xl:container mx-auto px-4 lg:px-8 py-2 text-white">
        <div className="grid md:grid-cols-2 mb-8 md:mb-32 gap-x-12 gap-y-8">
          <div className="md:col-span-2">
            <Link to="/">
              <GatsbyImage image={getImage(logo_image.image)}
                alt="Temp and Agnew Logo"
                className="max-w-[150px]" />
            </Link>
          </div>
          <Formik initialValues={{
            email: ''
          }}
            validate={validateForm}
            onSubmit={onSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <>
                <form className={clsx("flex flex-col gap-y-4", submitSuccess && "hidden")}
                  name="subscribe"
                  data-netlify="true"
                  onSubmit={handleSubmit}>
                  <h4 className="text-xl">Want to stay in the loop?</h4>
                  <p className="text-sm mb-2">Sign up below and receive new temp jobs straight to your inbox.</p>
                  <div className="mb-2">
                    <input type="email"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="py-2 px-4 w-full sm:w-8/12 lg:w-full bg-off-white border border-primary outline-none text-black focus:ring-1 focus:ring-primary focus:border-primary"
                      />
                    {errors.email && touched.email && (
                      <div className="text-red-500 text-sm ml-1 mt-1">
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <p className="text-sm mb-2">By signing up you agree to receive recurring automated marketing messages from Temp and Agnew. We won't spam you.</p>
                  <div>
                    <button type="submit"
                      disabled={isSubmitting}
                      className="w-full sm:w-auto border border-solid px-8 pt-3 pb-2.5 font-heading text-sm flex items-center justify-center transition-transform font-bold leading-4 bg-primary-light border-primary-light text-black rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={clsx(
                          "absolute animate-spinner h-5 w-5",
                          !isSubmitting && "hidden"
                        )}
                        viewBox="0 0 50 50"
                      >
                        <circle
                          className="animate-dash stroke-white"
                          cx="25"
                          cy="25"
                          r="20"
                          fill="none"
                          strokeWidth="5"
                          style={{ strokeLinecap: "round" }}
                        ></circle>
                      </svg>
                      <span className={clsx(isSubmitting && "opacity-0")}>
                        Subscribe
                      </span>
                    </button>
                  </div>
                </form>
                <div className={clsx(!submitSuccess && "hidden")}>
                  <p className="text-lg mb-4 text-white">
                    Thanks for subscribing!
                  </p>
                </div>
                <div className={clsx(!submitFail && "hidden")}>
                  <p className="text-lg mb-4 text-red-400 mt-2">
                    Something went wrong. Please try again or contact us.
                  </p>
                </div>
              </>
            )}
          </Formik>
          <div className="md:hidden">
            <hr />
          </div>
          <div className="grid sm:grid-cols-2 gap-x-6 gap-y-6">
            <div className="flex flex-col md:items-end">
              <div>
                <h4 className="text-lg mb-4">Temp Services</h4>
                <ul>
                  {navListOne.map((i,k) => (
                    <li key={k}
                      className="my-2 hover:underline text-white">
                      <a href={i.to}>{i.label}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex flex-col md:items-end">
              <div>
                <h4 className="text-lg mb-4">Corporate</h4>
                <ul>
                  {navListTwo.map((i,k) => (
                    <li key={k}
                      className="my-2 hover:underline text-white">
                      <a href={i.to}>{i.label}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="mb-6">
            <hr />
          </div>
          <div className="flex flex-col md:flex-row gap-x-4 text-sm">
            <div className="order-2 md:order-1">
              © {new Date().getFullYear()} {entity}.
            </div>
            <ul className="order-1 md:order-3 flex flex-1 md:justify-end items-center gap-x-4 mb-4 md:mb-0">
              {twitterUrl &&
                <FooterIcon url={twitterUrl}
                  icon="iconTwitter" />
              }
              {tiktokUrl &&
                <FooterIcon url={tiktokUrl}
                  icon="iconTiktok" />
              }
              {facebookUrl &&
                <FooterIcon url={facebookUrl}
                  icon="iconFacebook" />
              }
              {instagramUrl &&
                <FooterIcon url={instagramUrl}
                  icon="iconInstagram" />
              }
              {linkedInUrl &&
                <FooterIcon url={linkedInUrl}
                icon="iconLinkedIn" />
              }
            </ul>
          </div>
        </div>
      </div>
      
    </footer>
  )
}

export default Footer;
