import * as React from "react";
import SvgSprites from './svgSprites';
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Header from "./header"
import Footer from "./footer";
import { navigate } from '@reach/router';

const getPath = (path) => {
  const parts = path.split('/');
  return parts[1];
}

const Layout = ({ location, path, children }) => {
  const [lastPath, setLastPath] = React.useState(null);

  React.useEffect(() => {
    if(lastPath !== location.pathname) {
      setLastPath(location.pathname);
    }
    if(lastPath !== '/' && location.hash) {
      navigate(location.hash)
    }
  }, [location, lastPath]);

  const {
    entity
  } = useSiteMetadata();
  
  return (
    <div className="relative flex flex-col h-full text-black opacity-1">
      <Header noBackground={getPath(path) === '404'} />
      <main className="grow">{children}</main>
      <Footer entity={entity} />

      <SvgSprites />
    </div>
  )
}

export default Layout
