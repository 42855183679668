import React from "react"

const FooterIcon = ({ url, icon }) => {
  return (
    <li>
      <a
        href={url}>
        <svg className="w-5 h-5 md:w-4 md:h-4 fill-white md:fill-primary-extralight group-hover:fill-primary-dark transition-all">
          <use href={`#${icon}`}></use>
        </svg>
      </a>
    </li>
  )
}

export default FooterIcon
