import {graphql, useStaticQuery} from 'gatsby';

export const useSiteSocials = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(fields: {filename: {eq: "socials"}}) {
        frontmatter {
          facebookUrl
          linkedInUrl
          instagramUrl
          tiktokUrl
          twitterUrl
        }
      }
    }
  `);
  return query.mdx.frontmatter;
}