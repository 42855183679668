import React from "react";
import {Link} from 'gatsby';

const NavItem = ({item}) => {
  return (
    <li className="relative">
      <Link className="block text-white lg:text-sm hover:text-primary-dark transition whitespace-nowrap font-extrabold"
        to={item.to}>
        {item.label}
      </Link>
    </li>
  )
}

export default NavItem;